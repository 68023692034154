import React from "react";
import styled from "styled-components";
import { injectIntl } from "gatsby-plugin-intl"

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: null
        };
    }
    render() {
        const intl = this.props.intl;
        const { status } = this.state;
        return (
            <FormWrapper>
                <form
                    onSubmit={this.submitForm}
                    action="https://formspree.io/meqrelvd"
                    method="POST"
                >
                    <FormItem>
                    <h4>{intl.formatMessage({id:"contact.whatAreYouInterestedIn"})}</h4>
                        <div className="row">
                            <div className="input-field col s3">
                                <label>
                                    <input aria-label={intl.formatMessage({id:"contact.webDevelopment"})} value="Web development" name="type" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.webDevelopment"})}</span>
                                </label>
                            </div>
                            <div className="input-field col s3">
                                <label>
                                    <input aria-label={intl.formatMessage({id:"contact.mobileDevelopment"})} value="Mobile development" name="type" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.mobileDevelopment"})}</span>
                                </label>
                            </div>
                            <div className="input-field col s3">
                                <label>
                                    <input aria-label={intl.formatMessage({id:"contact.UIDesign"})} value="UI Design" name="type" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.UIDesign"})}</span>
                                </label>
                            </div>
                            <div className="input-field col s3">
                                <label>
                                    <input aria-label={intl.formatMessage({id:"contact.UXDesign"})} value="UX Design" name="type" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.UXDesign"})}</span>
                                </label>
                            </div>
                            <div className="input-field col s3">
                                <label>
                                    <input aria-label={intl.formatMessage({id:"contact.outsourcing"})} value="Outsourcing" name="type" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.outsourcing"})}</span>
                                </label>
                            </div>
                            <div className="input-field col s3">
                                <label>
                                    <input aria-label={intl.formatMessage({id:"contact.other"})} value="Other" name="type" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.other"})}</span>
                                </label>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem>
                        <h4>{intl.formatMessage({id:"contact.doYouHaveABudget"})}</h4>
                        <div className="row">
                            <div className="input-field col s12">
                                <label>
                                    <input aria-label="1,000 - 5,000 $USD" value="1,000 - 5,000 $USD" name="budget" type="radio" />
                                    <span>1,000 - 5,000 $USD</span>
                                </label>
                            </div>
                            <div className="input-field col s12">
                                <label>
                                    <input aria-label="5,000 - 10,000 $USD" value="5,000 - 10,000 $USD" name="budget" type="radio" />
                                    <span>5,000 - 10,000 $USD</span>
                                </label>
                            </div>
                            <div className="input-field col s12">
                                <label>
                                    <input aria-label="10,000 - 50,000 $USD" value="10,000 - 50,000 $USD" name="budget" type="radio" />
                                    <span>10,000 - 50,000 $USD</span>
                                </label>
                            </div>
                            <div className="input-field col s12">
                                <label>
                                    <input aria-label="Over 50,000 $USD" value="Over 50,000 $USD" name="budget" type="radio" />
                                    <span>Over 50,000 $USD</span>
                                </label>
                            </div>
                            <div className="input-field col s12">
                                <label>
                                    <input value="Other" aria-label={intl.formatMessage({id:"contact.other"})} name="budget" type="radio" />
                                    <span>{intl.formatMessage({id:"contact.other"})}</span>
                                </label>
                            </div>
                        </div>
                    </FormItem>
                    <div className="row">
                        <div className="input-field col s4">
                            <input id="name" type="text" name="name" aria-label={intl.formatMessage({id:"contact.fullName"})} className="validate" required />
                            <label htmlFor="name">{`${intl.formatMessage({id:"contact.fullName"})} *`} </label>
                        </div>
                        <div className="input-field col s4">
                            <input id="phone" type="text" name="phone" aria-label={intl.formatMessage({id:"contact.phoneNumber"})} className="validate" required />
                            <label htmlFor="phone">{`${intl.formatMessage({id:"contact.phoneNumber"})} *`} </label>
                        </div>
                        <div className="input-field col s4">
                            <input id="email" type="email" name="email" aria-label={intl.formatMessage({id:"contact.email"})} className="validate" required />
                            <label htmlFor="email">{`${intl.formatMessage({id:"contact.email"})} *`} </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field  col s12">
                            <textarea id="textarea" className="materialize-textarea" aria-label={intl.formatMessage({id:"contact.describeYourProject"})} name="description" data-length="1000" required />
                            <label htmlFor="description">{`${intl.formatMessage({id:"contact.describeYourProject"})} *`} </label>
                        </div>
                    </div>
                    <div className="submit-container">
                        {status === "ERROR" && <div className="error">{intl.formatMessage({id:"messageError"})}</div>}
                        {status === "SUCCESS" ?
                            <div className="btn btn--success">{intl.formatMessage({id:"messageSuccess"})}</div> :
                            <button
                                aria-label={intl.formatMessage({id:"contact.sendMessage"})}
                                className="btn btn--dark fade animated">
                                {intl.formatMessage({id:"contact.sendMessage"})}
                            </button>}
                    </div>
                </form>
            </FormWrapper>
        );
    }
    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ status: "SUCCESS" });
            } else {
                this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    }
}

const FormWrapper = styled.div`
  h4 {
    font-size: 20px;
  }
  .s3 {
    margin-left: 0 !important;
  }
  .error {
    color: #A50203;
    padding: 10px;
    margin: 15px 0;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9e9e9e !important;
      opacity: 1; /* Firefox */
    }
    .btn.btn--dark.animated.fade:hover:before{
      left:90% !important;
      opacity:1;
    }
    .submit-container {
      text-align: right;
      button {
        display: inline-block;
      }
    }
    @media(max-width: 768px) {
      .submit-container {
        text-align: center;
       }
    }
`;
const FormItem = styled.div`
  padding: 30px 0;
`;

export default injectIntl(ContactForm);