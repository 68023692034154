import React from "react";
import styled from "styled-components";
import ContactForm from "../components/forms/ContactForm";
import { useIntl, Link } from "gatsby-plugin-intl";

import worldDots from "../images/dot-map-background.webp";
import QobaltLogo from "../images/logo-only.png";
import SEO from "../components/seo";
import Layout from "../components/layout";

export default () => {
    const intl = useIntl();
    const BackButton = () => {
        return (
            <Link to="/" className="close-contact-page primary-close scrollbar-margin"
                  data-close="page" data-margin="scrollbar">
                <svg width="24px" height="24px" viewBox="0 0 32 24" version="1.1">
                    <g stroke="#3c3c3c" strokeWidth="1.5">
                        <path d="M7.51471863,3.51471863 L24.4852814,20.4852814" id="Line"></path>
                        <path d="M7.51471863,20.4852814 L24.4852814,3.51471863" id="Line 1"></path>
                    </g>
                </svg>
            </Link>
        );
    };
    return (
        <Layout showNav={false} showContact={false}>
            <ContactWrapper style={{backgroundImage: `url(${worldDots})`}}>
                <SEO title="Contact Qobalt Technologies" />
                <section className="top">
                    <div className="container-fluid">
                        <Link to="" className="logo-compact hidden-up-md" href="https://softwarebrothers.co/">
                            <img src={QobaltLogo} alt="Qobalt Technologies"/>
                        </Link>
                        <BackButton />
                    </div>
                </section>
                <div className="container-fluid">
                    <div className="content content--text">
                        <section className="section">
                            <h1>{intl.formatMessage({id:"contactButton"})}</h1>
                            <div className="section--description">
                                {intl.formatMessage({id:"contact.description"})}
                            </div>
                            <ContactForm/>
                        </section>
                    </div>
                </div>
            </ContactWrapper>
        </Layout>
    );
};

const ContactWrapper = styled.div`
  font-family: font-family: var(--secondary-font);
  background: #ffffff no-repeat center;
  min-height: calc(100vh - 80px);
  .section h1 {
        font-size: 3.5rem;
  }
  @media(min-width: 769px) {
      .section h1 {
        font-size: 4.5rem;
      }
      background-size: contain;
  }
  .top {
    height: 72px;
    position: fixed;
    width: 100vw;
    z-index: 2;
    background-color: #fff;
    img {
      height: 40px;
      display: block;
    }
    .container-fluid {
      position: relative;
      max-width: 1400px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
    }
  }
  .primary-close, .primary-close svg {
    width: 58px;
    height: 58px;
   }
`;